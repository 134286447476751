<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 pt-3 pb-3 bg-fafafa">

                <!-- Breadcrumb -->
                <breadcrumb />

                <div class="card card-body mb-2">

                    <p class="font-weight-bold m-0">Filtri di ricerca</p>

                    <p class="text-muted mb-3">
                        <small>
                            Compila i campi che seguono, la ricerca avverrà in automatico
                        </small>
                    </p>

                    <div class="row">
                        <div class="col-lg-3">
                            <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readUsersFilter" v-model="form.search.username" placeholder="Username">
                        </div>
                        <div class="col-lg-3">
                            <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readUsersFilter" v-model="form.search.name" placeholder="Nome">
                        </div>
                        <div class="col-lg-3">
                            <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readUsersFilter" v-model="form.search.surname" placeholder="Cognome">
                        </div>
                        <div class="col-lg-3">
                            <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readUsersFilter" v-model="form.search.title_user_type" placeholder="Tipologia">
                        </div>                                     
                    </div>
                </div>

                <loader v-if="loaders.reading" />

                <div v-else>

                    <div v-if="users.DB.length < 1" class="alert alert-warning m-0">
                        Ancora nessun utente
                    </div>

                    <div v-else>

                        <div class="card card-body" v-if="view.mode == `table`">
                            <div class="table-responsive m-0">
                                <table class="table table-bordered table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                Nome
                                            </th>
                                            <th scope="col">
                                                Cognome
                                            </th>
                                            <th scope="col">
                                                Username
                                            </th>
                                            <th scope="col">
                                                Tipologia
                                            </th>
                                            <th scope="col">
                                                Telefono
                                            </th>
                                            <th scope="col">
                                                Email
                                            </th>
                                            <th scope="col">
                                                Stato
                                            </th>
                                            <th scope="col">
                                                Accesso
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <router-link tag="tr" :to="`/user/utenti/modifica/${user.username}`" v-for="user of users.DB" :key="`user_${user.id}`" class="hover cursor-pointer" v-bind:class="{'disabled_invisible': functionDisabled(5, 'read')}">
                                            <td>
                                                {{user.name}}
                                            </td>
                                            <td>
                                                {{user.surname}}
                                            </td>
                                            <td class="text-truncate font-weight-bold f-family-monospace">
                                                {{user.username}}
                                            </td>
                                            <td>
                                                {{user.user_type.title}}
                                            </td>
                                            <td class="font-weight-bold f-family-monospace">
                                                {{user.phone}}
                                            </td>
                                            <td class="text-truncate font-weight-bold f-family-monospace">
                                                {{user.email}}
                                            </td>
                                            <td class="font-weight-bold" v-bind:class="{'text-danger': !user.password, 'text-success': user.password}">
                                                {{!user.password ? "Non confermato" : "Confermato"}}
                                            </td>
                                            <td class="font-weight-bold" v-bind:class="{'text-danger': !user.status, 'text-success': user.status}">
                                                {{!user.status ? "Bloccato" : "Attivo"}}
                                            </td>
                                        </router-link>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row" v-if="view.mode == `cards`">
                            <div v-for="user of users.DB" :key="user.id" div class="col-lg-4">
                                <div class="card card-body mb-2">
                                    <div class="row">
                                        <div class="col-7">
                                            <p class="text-uppercase font-weight-bold text-truncate">
                                                {{user.name}} {{user.surname}}
                                            </p>
                                            <p class="m-0 text-truncate">
                                                <small class="font-weight-bold">
                                                    {{user.user_type.title}}
                                                </small>
                                            </p>
                                            <p class="m-0">
                                                <small>
                                                    {{user.username}}
                                                </small>
                                            </p>
                                            <p class="m-0">
                                                <small>
                                                    {{user.phone}}
                                                </small>
                                            </p>
                                            <p class="m-0 text-truncate">
                                                <small>
                                                    {{user.email}}
                                                </small>
                                            </p>
                                        </div>
                                        <div class="col-5 text-right d-flex flex-column justify-content-between">

                                            <div v-show="!user.password" class="badge badge-danger text-truncate">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-unlock" viewBox="0 0 16 16">
                                                    <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                                                </svg> Non confermato
                                            </div>
                                            <div v-show="!user.status" class="badge badge-danger text-truncate">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle ml-2" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                                </svg> Bloccato
                                            </div>

                                            <router-link tag="button" :to="`/user/utenti/modifica/${user.username}`" class="btn btn-ifo btn-sm" :disabled="functionDisabled(5, 'read')">
                                                Gestisci
                                            </router-link>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="users.DB.length >= 30" class="card card-body p-2">
                            <button class="btn btn-ifo m-auto" @click="moreResults">Carica altri utenti</button>
                        </div>

                    </div>

                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <router-link class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" to="/user/utenti/aggiungi" v-bind:class="{'disabled': functionDisabled(5, 'create')}">
                            <span class="d-none d-md-block">Aggiungi utente</span> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </router-link>
                    </li>
                    <li class="nav-item" v-show="view.mode == `table`">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom" @click="view.mode == 'table' ? view.mode = 'cards' : view.mode = 'table'">
                            <span class="d-none d-md-block">Cards view</span> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            </svg>
                        </a>
                    </li>    
                    <li class="nav-item" v-show="view.mode == `cards`">
                        <a class="nav-link d-flex align-items-center justify-content-center justify-content-md-end p-3 a_bg-ifo border-bottom">
                            <span class="d-none d-md-block">Table view</span> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table ml-md-2 mt-md-1" viewBox="0 0 16 16">
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import store  from '@/store'

    export default {
        data() {
            return {
                users: {
                    DB: []
                },
                loaders: {
                    reading: true
                },
                form: {
                    search: {
                        username: ``,
                        name:     ``,
                        surname:  ``,
                        title_user_type: ``
                    }
                },
                pagination: {
                    skip: 0,
                    take: 30
                },
                view: {
                    mode: "table"
                }
            }
        },
        methods: {

            async readUsers() {

                const where = {

                }
                const orderBy = {
                    name: "asc"
                }
                
                const {
                    skip,
                    take
                } = this.pagination

                try {

                    const users = await this.axios.post(`user/users/read/search`, {where, orderBy, skip, take}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.users.DB = users.data

                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.msg,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async readUsersFilter() {

                this.loaders.reading = true

                if(this.isInSearching()) {

                    for(let val in this.form.search) {
                        this.form.search[val] = this.form.search[val].toUpperCase()
                    }

                    const where = {
                        AND: [
                            {
                                username: {
                                    contains: this.form.search.username
                                }
                            },
                            {
                                name: {
                                    contains: this.form.search.name
                                }
                            },
                            {
                                surname: {
                                    contains: this.form.search.surname
                                }
                            },
                            {
                                user_type: {
                                    title: {
                                        contains: this.form.search.title_user_type
                                    }
                                }
                            }
                        ]
                    }
                    const orderBy = {
                        name: "asc"
                    }

                    this.pagination.skip = 0

                    const {
                        skip,
                        take
                    } = this.pagination

                    try {

                        const users = await this.axios.post(`user/users/read/search`, {where, orderBy, skip, take}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        this.users.DB = users.data

                    }
                    catch(e) {

                        this.$toast.open({
                            message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                            type:    `error`
                        })

                    }
                    finally {
                        this.loaders.reading = false
                    }
                }
                else {
                    this.readUsers()
                }
            },

            moreResults() {
                this.pagination.take += 30
                this.readUsersFilter()
            },
            isInSearching() {
                return this.form.search.username.length >= 3 || this.form.search.name.length >= 3 || this.form.search.surname.length >= 3 || this.form.search.title_user_type.length >= 3
            },
            functionDisabled(id_function, action) {
                for(let sFunction of store.getters.softwareFunctions) {
                    if(sFunction.id_software_functions == id_function && !sFunction[action]) {
                        return true
                    }
                }
            }

        },
        mounted() {
            this.readUsers()
        }
    }

</script>