<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10 py-3 bg-fafafa">

                <breadcrumb />

                <div v-if="DB.appointments.length > 0">

                    <div class="card card-body mb-3">

                        <div class="row">
                            <div class="col-xl-7 border-right">

                                <h1 class="f-size-2_2-em">
                                    {{DB.appointments.length}} Appuntamenti
                                </h1>

                                <div class="w-25">
                                    <hr>
                                </div>

                                <p class="text-danger" v-if="form.search.from_year > form.search.to_year">Hai errato nell'inserimento del 1 e 2 anno</p>

                                <p v-else>In totale sono stati creati {{DB.appointments.length}} appuntamenti {{form.search.from_year == form.search.to_year ? `nell'anno ${form.search.to_year}` : `dall'anno ${form.search.from_year} all'anno ${form.search.to_year}`}}. Il mese con la maggior parte degli appuntamenti è {{get_month_max_appointments.m}} con {{get_month_max_appointments.q}} appuntamenti e quello con la minor quantità è {{get_month_min_appointments.m}} con {{get_month_min_appointments.q}} appuntamenti. Gli appuntamenti sono associati con le seguenti entità:</p>

                                <div class="table-responsive">
                                    <table class="table table-bordered table-sm">
                                        <tbody>
                                            <tr v-for="(relation, index) of get_relations" :key="`relation_${relation}`" style="cursor: default">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td>
                                                    {{relation | relation_name_translate}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="col-xl-5">

                                <h4 class="m-0">Date</h4>

                                <p class="text-muted w-75">
                                    <small>
                                        Impostando lo stesso valore nei campi che seguono vedrai le statistiche dell'anno corrente.
                                    </small>
                                </p>

                                <div class="w-25">
                                    <hr>
                                </div>

                                <form class="form-inline">

                                    <label for="from_year" class="mr-2 mb-2">1 anno</label>
                                    <input type="number" class="form-control mr-2 mb-2" id="from_year" min="2022" max="9999" step="1" v-model.number="form.search.from_year" @change="form.search.to_year < form.search.from_year ? form.search.to_year = form.search.from_year : null">

                                    <label for="to_year" class="mr-2 mb-2">2 anno</label>
                                    <input type="number" class="form-control mb-2" id="to_year" :min="form.search.from_year" max="9999" step="1" v-model.number="form.search.to_year">

                                </form>

                                <h4 class="m-0">Entità</h4>

                                <p class="text-muted w-75">
                                    <small>
                                        I grafici possono essere combinati con le entità, per visualizzarne maggiori dettagli.
                                    </small>
                                </p>

                                <div class="w-25">
                                    <hr>
                                </div>

                                <div class="custom-control custom-switch" v-for="relation of get_relations" :key="`relation_check_${relation}`">
                                    <input type="checkbox" class="custom-control-input" :id="`relation_check_${relation}`" v-model="form.show[relation]" @change="update_entity(relation)">
                                    <label class="custom-control-label" :for="`relation_check_${relation}`">{{relation | relation_name_translate}}</label>
                                </div>

                            </div>
                        </div>                   

                    </div>
                    <div class="card card-body mb-3">
                        <line_chart
                            :chart-data = "charts.line.data"
                            :options    = "charts.line.options"
                            :height     = "150"
                            ref         = "skills_chart_line"
                        />
                    </div>
                    <div class="card card-body">
                        <doughnut_chart
                            :chart-data = "charts.doughnut.data"
                            :options    = "charts.doughnut.options"
                            :width      = "300"
                            :height     = "300"
                            ref         = "skills_chart_doughnut"
                        />
                    </div>

                </div>
                <div class="card card-body" v-else>
                    <loader/>
                </div>

            </div>
            <div class="col-2 h-min-100-calc-lg_logged border-left p-0">
                <navbar />
            </div>
        </div>
    </div>
</template>

<script>

    import loader         from '@/components/loader.vue'
    import navbar         from '@/components/navbar_analytics.vue'
    import line_chart     from '@/components/line.vue'
    import doughnut_chart from '@/components/doughnut.vue'
    import store          from '@/store'
    import moment         from 'moment'

    export default {
        components: {
            loader,
            navbar,
            line_chart,
            doughnut_chart
        },
        data() {
            return {
                DB: {
                    appointments: []
                },
                form: {
                    search: {
                        from_year: moment().format("YYYY"),
                        to_year:   moment().format("YYYY")
                    },
                    show: {} // Riempimento dinamico tramite v-model
                },
                charts: {
                    line: {
                        options: store.getters.line_options,
                        data: {
                            labels:   moment.months(),
                            datasets: []
                        }
                    },
                    doughnut: {
                        options: store.getters.doughnut_options,
                        data: {
                            labels:   moment.months(),
                            datasets: []
                        }
                    }
                },
                key_entity_for_labels: {
                    rooms: "title",
                    user:  "username"
                }   
            }
        },
        methods: {
            async read_appointments() {
                try {

                    this.DB.appointments = (
                        await this.axios.get(`user/appointments/read_all_for_analytics`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data

                    // Set data chart
                    this.update_charts()

                }
                catch(e) {
                    this.$toast.open({
                        message: e.response.data.msg,
                        type: "error"
                    })
                }
            },
            read_appointments_month() {

                let counter = [0,0,0,0,0,0,0,0,0,0,0,0]

                for(const appointment of this.DB.appointments) {

                    if(moment(appointment.date).format("YYYY") >= this.form.search.from_year && moment(appointment.date).format("YYYY") <= this.form.search.to_year) {

                        counter[
                            parseInt(
                                moment(appointment.date).format("M")
                            ) - 1
                        ]++

                    }

                }

                return counter

            },
            read_appointments_month_custom(entity, position, label) {

                // Contatori in scala mensile
                let counter = [0,0,0,0,0,0,0,0,0,0,0,0]

                // Ciclo degli appuntamenti
                for(const appointment of this.DB.appointments) {

                    // Se appuntamento è nel corretto range e il filtro corrisponde al parametro passato
                    if(
                        moment(appointment.date).format("YYYY") >= this.form.search.from_year &&
                        moment(appointment.date).format("YYYY") <= this.form.search.to_year &&
                        appointment[entity][position] == label
                    ) {

                        counter[
                            parseInt(
                                moment(appointment.date).format("M")
                            ) - 1
                        ]++

                    }                        

                }

                return counter

            },
            update_charts() {

                this.update_chart([
                    {
                        type: "line",
                        datasets: [{
                            data: this.read_appointments_month(),
                            label: "Appuntamenti totale",
                            borderColor: "#5c1b24",
                            fill: false
                        }]
                    },
                    {
                        type: "doughnut",
                        datasets: [{
                            backgroundColor: this.generate_color(12),
                            data: this.read_appointments_month()
                        }]
                    }
                ])

            },
            update_chart(charts) {

                let labels   = []
                let datasets = []

                for(const chart of charts) {

                    switch(chart.type) {

                        case "line":
                            this.charts[chart.type].data.datasets = chart.datasets
                        break

                        case "doughnut":

                            // Se sono state selezionate delle relazioni
                            if(!this.all_checks_are_false()) {

                                // Dato che il doughnut ha un trattamento custom..
                                for(const dataset of chart.datasets) {

                                    labels.push(dataset.label)

                                    let counter = 0

                                    for(const q of dataset.data) {
                                        counter += q
                                    }

                                    datasets.push(counter)

                                }

                                this.charts[chart.type].data.labels   = labels
                                this.charts[chart.type].data.datasets = [{
                                    backgroundColor: this.generate_color(datasets.length),
                                    data: datasets
                                }]

                            }
                            // Se non sono state selezionate delle relazioni
                            else {

                                this.charts[chart.type].data.labels   = moment.months()
                                this.charts[chart.type].data.datasets = chart.datasets

                            }

                        break

                    }
                }

            },
            all_checks_are_false() {

                // Se non è stato ancora selezionato niente
                if(Object.keys(this.form.show).length === 0) return true

                // Cerca tra le selezioni se ci sono dei true
                for(const key in this.form.show) {
                    if(this.form.show[key]) return false
                }

                return true

            },
            disable_other_checkboxs(entity) {

                for(const key in this.form.show) {

                    // Se la chiave è diversa dalla entità presa in causa metti flag a false
                    if(key != entity) this.form.show[key] = false

                }

            },
            update_entity(entity) {

                // Controlliamo se tutte le entità sono a false
                if(this.all_checks_are_false()) {
                    this.update_charts()
                }
                else {

                    // Se valore true inserisci
                    if(this.form.show[entity]) {
                        // Disabilita le altre checkboxs
                        this.disable_other_checkboxs(entity)
                        // Visualizza la nuova entità
                        this.push_entity(entity)
                    }

                }


                // Aggiorna grafici
                this.$refs.skills_chart_line.update()
                this.$refs.skills_chart_doughnut.update()

            },
            push_entity(entity) {

                this.update_chart([
                    {
                        type: "line",
                        datasets: this.read_datasets_to_push(entity) // Ricaviamo le etichette e i dati da inserire
                    },
                    {
                        type: "doughnut",
                        datasets: this.read_datasets_to_push(entity)
                    }
                ])
                

            },
            read_datasets_to_push(entity) {

                // Datasets da passare nel grafico
                let datasets = []

                // Ricaviamo la chiave primaria che sarà la label per il grafico
                const position = this.key_entity_for_labels[entity]

                // Cicliamo gli appuntamenti
                for(const appointment of this.DB.appointments) {
                    // Se la determinata label non è stata ancora inserita
                    if(!datasets.some(dataset => dataset.label == appointment[entity][position])) {
                        datasets.push({
                            label: appointment[entity][position],
                            data: this.read_appointments_month_custom(entity, position, appointment[entity][position]),
                            borderColor: this.generate_color(1),
                            fill: false
                        })
                    }
                }

                return datasets

            },
            generate_color(quantity, colors = []) {

                const generate = () => `#${Math.floor(Math.random()*16777215).toString(16)}`

                for(let counter = 0; counter < quantity; counter++) {
                    colors.push(
                        generate()
                    )
                }

                return quantity > 1 ? colors : colors[0]

            },
            show_doughnut_legend() {
                this.charts.doughnut.options.legend.display = true
            }
        },
        mounted() {
            this.read_appointments()
            this.show_doughnut_legend()
        },
        computed: {
            
            get_month_max_appointments() {

                // Lettura appuntamenti per mese
                const mounths = this.read_appointments_month()

                // Ricerca del valore massimo
                const month = mounths.indexOf(
                    Math.max(...mounths)
                )

                // Return del mese e la quantità
                return {
                    m: moment().month(
                        month
                    ).format("MMMM"),
                    q: mounths[month]
                    
                }

            },
            get_month_min_appointments() {
                
                // Lettura appuntamenti per mese
                const mounths = this.read_appointments_month()

                // Ricerca del valore massimo
                const month = mounths.indexOf(
                    Math.min(...mounths)
                )

                // Return del mese e la quantità
                return {
                    m: moment().month(
                        month
                    ).format("MMMM"),
                    q: mounths[month]
                    
                }

            },
            get_relations() {

                const appointment = this.DB.appointments[0]

                let keys = []
                let relations = []

                for(const key in appointment) {
                    if(typeof appointment[key] == "object") {
                        keys.push(key)
                    }
                }
                for(const key of keys) {
                    if(appointment[key]) relations.push(key)
                }

                return relations

            }

        }
    }

</script>